@font-face {
  font-family: "EONBrixSans";
  src: url("EON_BrixSansWeb-Black.eot");
  src: url("EON_BrixSansWeb-Black.eot?#iefix") format("embedded-opentype"), url("EON_BrixSansWeb-Black.woff2") format("woff2"), url("EON_BrixSansWeb-Black.woff") format("woff"), url("EON_BrixSansWeb-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "EONBrixSans";
  src: url("EON_BrixSansWeb-Regular.eot");
  src: url("EON_BrixSansWeb-Regular.eot?#iefix") format("embedded-opentype"), url("EON_BrixSansWeb-Regular.woff2") format("woff2"), url("EON_BrixSansWeb-Regular.woff") format("woff"), url("EON_BrixSansWeb-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EONBrixSans";
  src: url("EON_BrixSansWeb-Medium.eot");
  src: url("EON_BrixSansWeb-Medium.eot?#iefix") format("embedded-opentype"), url("EON_BrixSansWeb-Medium.woff2") format("woff2"), url("EON_BrixSansWeb-Medium.woff") format("woff"), url("EON_BrixSansWeb-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EONBrixSans";
  src: url("EON_BrixSansWeb-Bold.eot");
  src: url("EON_BrixSansWeb-Bold.eot?#iefix") format("embedded-opentype"), url("EON_BrixSansWeb-Bold.woff2") format("woff2"), url("EON_BrixSansWeb-Bold.woff") format("woff"), url("EON_BrixSansWeb-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
